export const plugins = [{
    plugin: require('bernie-plugin-isomorphic-logger/dist/browser'),
    options: JSON.parse('{}')
  },{
    plugin: require('bernie-plugin-context/dist/browser'),
    options: JSON.parse('{"enablePlatformContextProvider":true,"ignoreBexApiErrors":true,"customContextFetcherPath":"./src/server/context-setter.js"}')
  },{
    plugin: require('bernie-plugin-mobx/dist/browser'),
    options: JSON.parse('{}')
  },{
    plugin: require('bernie-plugin-experiment-store/dist/browser'),
    options: JSON.parse('{}')
  },{
    plugin: require('bernie-plugin-apollo/dist/browser'),
    options: JSON.parse('{}')
  },{
    plugin: require('bernie-plugin-global-components-context/dist/browser'),
    options: JSON.parse('{}')
  },{
    plugin: require('bernie-plugin-clickstream/dist/browser'),
    options: JSON.parse('{"brandClickstream":"uis-prime"}')
  },{
    plugin: require('bernie-plugin-egds-styles/dist/browser'),
    options: JSON.parse('{}')
  }]